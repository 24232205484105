import {AppBar, Toolbar, IconButton, Typography} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import {useNavigate} from 'react-router-dom';
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";


const AppBarSimple = ({titlePage, showBackButton = true, onSaveClick, onDeleteClick}) => {
    const navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1);
    };


    return (
        <AppBar position="absolute">
            <Toolbar
                sx={{
                    pr: '24px', // mantieni il padding destro quando il drawer è chiuso
                }}
            >
                {showBackButton && ( // Mostra il pulsante indietro solo se showBackButton è true
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="back"
                        onClick={handleGoBack}
                        sx={{mr: 2}}
                    >
                        <ArrowBackIcon/>
                    </IconButton>
                )}
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{flexGrow: 1}}
                >
                    {titlePage}
                </Typography>
                {onSaveClick && (
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="action"
                        onClick={onSaveClick}
                    >
                        <CheckIcon/>
                    </IconButton>
                )}
                {onDeleteClick && (
                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="action"
                        onClick={ onDeleteClick}
                    >
                        <DeleteIcon/>
                    </IconButton>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default AppBarSimple;
import React, {useState, useEffect} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {fetchDataGet} from "../utils/API/apiService";
import Container from '@mui/material/Container';
import {Grid} from '@mui/material';

import FeaturedPost from '../components/Post/FeaturedPost';
import Box from '@mui/material/Box';

function Home() {
    const [recipes, setRecipes] = useState([]);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const fetchRecipes = async () => {
        try {
            const pageSize = 8; // Numero di ricette per pagina
            console.log({page});
            console.log({hasMore});
            const queryParams = {pageNumber: page, pageSize: pageSize, search: ''};
            const fetchedRecipes = await fetchDataGet("recipe/recipe/recipesPage", queryParams);
            console.log('fetchedRecipes: ',fetchedRecipes);
            if (fetchedRecipes.content.length === 0) {
                setHasMore(false); // Non ci sono più ricette da caricare
            } else {
                setRecipes([...recipes, ...fetchedRecipes.content]); // Aggiungi le nuove ricette alla lista esistente
                // setRecipes(prevRecipes=> {return [...new Set ([...recipes, ...fetchedRecipes.content])]})
                setPage(prevPage => prevPage + 1);
                //setPage(page + 1); // Incrementa il numero di pagina per la prossima chiamata API
                console.log({page});
            }
        } catch (error) {
            console.error('Errore durante il recupero delle ricette:', error);
        }
    };

    useEffect(() => {
        fetchRecipes();
    }, [page]); // Chiamata API iniziale al caricamento del componente

    return (
        <div>


                <Box sx={{display: 'flex', position: 'relative'}}>

                    {/* <Structure  pageTitleLocal="Home"/> */}
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                            marginTop: '64px',
                            overflowX: 'hidden'
                        }}
                    >
                        <Container maxWidth="lg" sx={{mt: 4, mb: 4, pb: 4,overflowX: 'hidden' }}>
                            <main>
                                <InfiniteScroll
                                    dataLength={recipes.length}
                                    next={() => setPage(prevPage => prevPage + 1)}
                                    hasMore={hasMore}
                                    loader={<h4>Loading...</h4>}
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                >
                                <Grid container spacing={4}>
                                    {recipes.map((post) => (
                                        <FeaturedPost key={post.id} post={post}/>
                                    ))}
                                </Grid>
                                </InfiniteScroll>
                            </main>
                        </Container>
                    </Box>
                </Box>


        </div>
    );
}

export default Home;
import React, {useEffect, useState} from 'react';
import {Button, Container, TextField} from '@mui/material';
import AppBarSimple from '../../components/AppBarSimple';
import IngredientsList from '../../components/ingredients/IngredientsList';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {fetchDataDelete, fetchDataGet, fetchDataPost, uploadImageApi} from "../../utils/API/apiService";
import ConfirmationDialog from "../../components/general/ConfirmationDialog";
import ImageCard from "../../components/general/ImageDownload";
import ImageUpload from "../../components/general/ImageUpload";
import { useLoading } from '../../utils/loader/LoadingProvider';

const ViewRecipe = () => {

    const location = useLocation();
    const {editMode} = location.state || {editMode: true};
    const userId = parseInt(localStorage.getItem("userId"), 10);
    const { showLoading, hideLoading } = useLoading();
    let {id} = useParams();
    const [postDetails, setPostDetails] = useState({
        id: null,
        ownerId: null,
        title: '',
        description: '',
        instruction: '',
        photoId: null,
        ingredients: [],
    });
    const [isEditMode, setIsEditMode] = useState(editMode);
    const navigate = useNavigate();

    const [file, setFile] = useState(null);

    const handleEdit = () => {
        setIsEditMode(true);
    };

    const [openConfirmDialog, setOpenConfirmDialog] = useState({open: false, recipeId: null});
    const handleOpenDialogDeleteIngredient = (id) => {
        setOpenConfirmDialog({open: true, recipeId: id});
    };
    const handleCancelDelete = () => {
        setOpenConfirmDialog({open: false, recipeId: null});
    };

    const handleConfirmDelete = async () => {
        try {
            const result = await fetchDataDelete(`recipe/recipe/delete/${postDetails.id}`)
                .then(() => {
                    navigate(-1)
                });

        } catch (error) {
            console.error('Errore durante la cancellazione della ricetta:', error);
        }
    };


    useEffect(() => {
        // Effettua la chiamata API per ottenere i dettagli del post utilizzando l'ID
        const fetchPostDetails = async () => {
            try {

                const response = await fetchDataGet(`recipe/recipe/recipe/${id}`);
                const viewRecipe = {
                    id: response.id,
                    title: response.title,
                    instruction: response.instruction,
                    description: response.description,
                    ownerId: response.ownerId,
                    photoId: response.photoId,
                    ingredients: response.ingredientsRecipe.map(item => ({
                        id: item.id,
                        name: item.ingredient.name,
                        unitMeasure: item.ingredient.unitMeasure,
                        quantity: item.quantity,
                        prefix: item.metricPrefix ? item.metricPrefix : ' '
                    }))
                };
                // console.log(viewRecipe);
                setPostDetails(viewRecipe);
            } catch (error) {
                console.error('Errore durante il recupero dei dettagli della recipe:', error);
            }
        };

        fetchPostDetails();
    }, [id]);


    const handleChange = (e) => {
        const {name, value} = e.target;
        setPostDetails((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        showLoading();
        try {
            let photoId=postDetails.photoId?postDetails.photoId: null;
            if(file){
                // console.log(postDetails.photoId);
                const response = await uploadImageApi(`store/${postDetails.photoId?postDetails.photoId+"/storageEdit/":"0/storage/"}${userId}`, file);
                // console.log(response);
                photoId=response?.id;
            }
            // console.log(postDetails);
            const editedRecipe = {
                id: postDetails.id,
                title: postDetails.title,
                instruction: postDetails.instruction,
                description: postDetails.description,
                photoId:photoId
            };
            const result = await fetchDataPost('recipe/recipe/editRecipes', editedRecipe)
                .then(() => {
                    navigate(-1)
                });

        } catch (error) {
            console.error('Errore durante il salvataggio della ricetta:', error);
        } finally {
            hideLoading();
        }
    };


    return (
        <div>
            <AppBarSimple titlePage={'Recipe'} showBackButton={true}
                          onSaveClick={isEditMode ? handleSubmit : isEditMode}
                          onDeleteClick={((parseInt(postDetails.ownerId, 10) === userId)
                              && !isEditMode) ? () => handleOpenDialogDeleteIngredient(postDetails.id) : false}
            />

            <Container maxWidth="sm" sx={{mt: 8, mb: 4}}>
                <form>
                    <ImageCard
                        photoId={postDetails.photoId}
                        sx={{
                            width: {
                                xs: '100%',  // Larghezza per schermi extra small (mobile)
                                sm: '50%',   // Larghezza per schermi small (tablet)
                                md: '40%',   // Larghezza per schermi medium (PC)
                            },
                            height: {
                                xs: '200px', // Altezza per schermi extra small (mobile)
                                sm: '300px', // Altezza per schermi small (tablet)
                                md: '400px', // Altezza per schermi medium (PC)
                            },
                            objectFit: 'contain', // Cover
                            display: { sm: 'block' },
                            paddingBottom: 2,
                            paddingTop: 2
                        }}
                    />
                    {
                        isEditMode ?
                            <div style={{maxWidth: '600px', margin: 'auto', textAlign: 'center'}}>
                                <ImageUpload setFile={setFile} />
                            </div>
                            : null
                    }
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Titolo"
                        name="title"
                        value={postDetails.title}
                        InputProps={{readOnly: !isEditMode}}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Descrizione"
                        name="description"
                        value={postDetails.description}
                        InputProps={{readOnly: !isEditMode}}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Istruzioni"
                        name="instruction"
                        multiline
                        rows={4}
                        value={postDetails.instruction}
                        InputProps={{readOnly: !isEditMode}}
                        onChange={handleChange}
                    />

                    {(parseInt(postDetails.ownerId, 10) === userId)
                    && !isEditMode
                        ?
                        <Button onClick={handleEdit} variant="contained" color="primary">
                            Edit
                        </Button> : null}

                    <div>
                        <IngredientsList
                            ingredients={postDetails.ingredients ? postDetails.ingredients : []}
                            setFormData={setPostDetails}
                            handleViewAddIngredientButton={isEditMode}
                            editMode={isEditMode}
                        />
                    </div>
                </form>
                <ConfirmationDialog
                    open={openConfirmDialog.open}
                    title="Conferma eliminazione"
                    message="Sei sicuro di voler eliminare questa ricetta?"
                    confirmButtonText="Conferma"
                    cancelButtonText="Annulla"
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
            </Container>
        </div>
    );
};

export default ViewRecipe;

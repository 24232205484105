// src/routes.js

import React from "react";
import {Routes, Route} from "react-router-dom";
import Home from "../pages/Home.js";
import CookBook from "../pages/Cookbook.js";
import NewRecipe from "../pages/addElement/newRecipe.js";
import About from "../pages/About";
// import Dashboard from "../pages/Dashboard.js";
import Blog from "../pages/Blog.js";
import {Auth} from 'react-auth-component'
import 'react-auth-component/dist/index.css'
import {BASE_URL} from "../utils/API/apiService.js";
import Structure from "../components/principal/Structure.js";
import ViewRecipe from "../pages/addElement/viewRecipe";


export const AppRoutes = () => (
    <Routes>
        <Route path="menu" element={<Structure/>}>
            <Route path="home" element={<Home/>}/>
            <Route path="cookbook" element={<CookBook/>}/>
            {/*<Route path="about" element={<About/>}/>*/}
            {/*<Route path="dashboard" element={<Blog/>}/>*/}
        </Route>
        <Route path="cookbook/:id" element={<ViewRecipe/>} />
        <Route path="newrecipe" element={<NewRecipe/>}/>
        <Route
            // exact
            path="/"
            element={
                <Auth
                    PageAuthenticated="menu/home"
                    urlRegister={`${BASE_URL}/auth/register`}
                    urlLogin={`${BASE_URL}/auth/login`}
                    tokenServerName="jwt"
                />}
        />
    </Routes>

);

import React from "react";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {styled} from "@mui/system";
import {useTheme} from '@mui/material/styles';
import {MainListItems} from "../drawer/MenuList";
import {MainListItemsMobile} from "../drawer/MenuListMobile";

const drawerWidth = 240; // Larghezza del Drawer

const CustomDrawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile'})(
    ({theme, open, isMobile}) => ({

        '& .MuiDrawer-paper': {
            position: 'absolute',
            height: '100vh',
            overflow: 'auto',
            display: isMobile && !open ? 'none' : 'block',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const CustomDrawerComponent = ({open, toggleDrawer}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Controlla se lo schermo è di dimensioni mobili
    const handleListItemClick = () => {
        if (open) {
            toggleDrawer(); // Chiude il drawer solo se è aperto
        }
    };


    return (
        <CustomDrawer variant="permanent" open={open} isMobile={isMobile}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon/>
                </IconButton>
            </Toolbar>
            <Divider/>
            <List component="nav">
                {isMobile ?
                    < MainListItemsMobile closeDrawer={handleListItemClick}/>
                    : <MainListItems closeDrawer={handleListItemClick}/>
                }

                <Divider sx={{my: 1}}/>
                {/* {secondaryListItems} */}
            </List>
        </CustomDrawer>
    );
};

export default CustomDrawerComponent;

// import {useState, useEffect} from "react";
import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import {Link} from "react-router-dom";
import ImageCard from "../general/ImageDownload";
const truncateText = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
function FeaturedPost(props) {
  const { post } = props;

  return (
    <Grid item xs={12} md={6} sx={{ padding: 1, boxSizing: 'border-box'  }}>
      <CardActionArea maxWidth="lg"
                      sx ={{width: '100%'}}
      >
        <Card sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'row' } }}>
          <CardContent
               sx={{ flex: 1, width: { xs: '40%' } }}
          >
            <Typography component="h2" variant="h5" sx={truncateText}>
              {post.title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" sx={truncateText}>
              {post.description}
            </Typography>
            <Typography variant="subtitle1" paragraph sx={truncateText}>
              {post.instruction}
            </Typography>
            <Typography component={Link}  state= {{ editMode: false }} to={`/cookbook/${post.id}`} variant="subtitle1" color="primary">
              Continue reading...
            </Typography>
          </CardContent>
          <ImageCard photoId={post.photoId}
                     sx={{
                       display: {sm: 'block'},
                         width: {
                             xs: '150px',  // Larghezza per schermi extra small (mobile)
                             sm: '50%',   // Larghezza per schermi small (tablet)
                             md: '40%',   // Larghezza per schermi medium (PC)
                         },
                         height: {
                             xs: '200px', // Altezza per schermi extra small (mobile)
                             sm: '300px', // Altezza per schermi small (tablet)
                             md: '400px', // Altezza per schermi medium (PC)
                         },
                        objectFit: 'cover',
                     }}
          />
        </Card>
      </CardActionArea>
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.shape({
    // date: PropTypes.string.isRequired,
    // description: PropTypes.string.isRequired,
    // image: PropTypes.string.isRequired,
    // imageLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default FeaturedPost;
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./routes/routes"; // Assicurati che il percorso sia corretto
import { ToastContainer } from 'react-toastify';
import LoadingProvider from "./utils/loader/LoadingProvider";
import './App.css';

function App() {
  return (
    <div className="App">
      <LoadingProvider>
        <Router>
          <AppRoutes />
        </Router>
        <ToastContainer />
      </LoadingProvider>
    </div>
  );
}

export default App;

import React, {useRef, useState} from 'react';
import {Button, Typography} from '@mui/material';
import {styled} from '@mui/system';

const UploadContainer = styled('div')({
    border: '2px dashed #cccccc',
    borderRadius: '10px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#f5f5f5',
    },
});

const Input = styled('input')({
    display: 'none',
});

const ImageUpload = ({setFile}) => {
    const fileInputRef = useRef(null);

    const [image, setImage] = useState(null);
    const handleImageUpload = (file) => {
        const reader = new FileReader();
        setFile(file);
        reader.onloadend = () => {
            // console.log(reader.result);
            setImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        
        if (file) {
            uploadImage(file);
        }
    };

    const uploadImage = async (file) => {
        handleImageUpload(file);
    };

    return (
        <>
            {!image &&
                <UploadContainer onClick={handleClick}>
                    <Typography variant="h6">Click to upload an image</Typography>
                    <Input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                </UploadContainer>
            }
            {image && (
                <div style={{marginTop: '20px'}}>
                    <Typography variant="h5">Uploaded Image:</Typography>
                    <img src={image} alt="Uploaded" style={{maxWidth: '100%', height: 'auto', marginTop: '10px'}}/>
                </div>
            )}
        </>
    );
};

export default ImageUpload;

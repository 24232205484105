import React, {useState} from 'react';
import {Container, TextField, Typography } from '@mui/material';
import AppBarSimple from '../../components/AppBarSimple';
import {fetchDataPost, uploadImageApi} from '../../utils/API/apiService';
import IngredientsList from '../../components/ingredients/IngredientsList';
import {useNavigate} from 'react-router-dom';
import ImageUpload from "../../components/general/ImageUpload";
import { useLoading } from '../../utils/loader/LoadingProvider';


const NewRecipe = () => {


    const navigate = useNavigate();
    const { showLoading, hideLoading } = useLoading();


    const [formData, setFormData] = useState({
        title: '',
        description: '',
        instruction: '',
        ingredients: [],
    });

    const userId = parseInt(localStorage.getItem("userId"), 10);


    // const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
    //
    // const handleImageUpload = (file) => {
    //     const reader = new FileReader();
    //     setFile(file);
    //     reader.onloadend = () => {
    //         console.log(reader.result);
    //         setImage(reader.result);
    //     };
    //     reader.readAsDataURL(file);
    // };
    // const [ingredientSelected, setIngredientSelected] = useState({
    //   id: null,
    //   name: "",
    //   unitMeasure: "",
    //   quantity: null
    // });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleSubmit = async () => {
        showLoading();
        try {
            if (!formData.title.trim()) {
                alert('Il campo "Titolo" è obbligatorio.');
                return;
            }
            let photoId= null;
            if(file){
                const response = await uploadImageApi(`store/0/storage/${userId}`, file);
                // console.log(response);
                photoId=response?.id;
            }
            // console.log(formData);
            const createRecipe = {
                title: formData.title,
                instruction: formData.instruction,
                description: formData.description,
                photoId:photoId,
                ingredientsRecipe: formData.ingredients.map(item => ({
                    ingredient: {
                        id: item.id,
                        name: item.name,
                        unitMeasure: item.unitMeasure
                    },
                    quantity: item.quantity,
                    metricPrefix: item.metricPrefix ? item.metricPrefix : ' '
                }))
            };
            // console.log(image);

            // const formData = { search: searchTerm };
            const result = await fetchDataPost('recipe/recipe/addRecipes', createRecipe)
                .then(() => {
                    navigate(-1)
                });
        } catch (error) {
            console.error('Errore durante il salvataggio della ricetta:', error);
        } finally {
            hideLoading();
        }
    };

    return (
        <div>
            <AppBarSimple titlePage={'New Recipe'} onSaveClick={handleSubmit}/>

            <Container maxWidth="sm" sx={{mt: 8, mb: 4}}>
                <form onSubmit={handleSubmit}>
                    <div style={{maxWidth: '600px', margin: 'auto', textAlign: 'center'}}>
                        <ImageUpload setFile={setFile} />
                        {/*<ImageUpload onImageUpload={handleImageUpload} element={"recipe"} idUser={parseInt(localStorage.getItem("userId"), 10)} />*/}
                        {/*{image && (*/}
                        {/*    <div style={{marginTop: '20px'}}>*/}
                        {/*        <Typography variant="h5">Uploaded Image:</Typography>*/}
                        {/*        <img src={image} alt="Uploaded" style={{maxWidth: '100%', marginTop: '10px'}}/>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Titolo"
                        name="title"
                        required
                        value={formData.title}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Descrizione"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Istruzioni"
                        name="instruction"
                        multiline
                        rows={4}
                        value={formData.instruction}
                        onChange={handleChange}
                    />

                    <div>
                        <IngredientsList
                            ingredients={formData.ingredients}
                            setFormData={setFormData}
                            // ingredientSelected={ingredientSelected}
                            // setIngredientSelected={setIngredientSelected}
                            handleViewAddIngredientButton={true}
                            // handleAddIngredient={() => {
                            //   setFormData((prevData) => ({
                            //     ...prevData,
                            //     ingredients: [
                            //       ...prevData.ingredients,
                            //       { name: ingredientSelected.name, quantity: ingredientSelected.quantity }
                            //     ]
                            //   }));
                            //   setIngredientSelected({
                            //     id: null,
                            //     name: "",
                            //     unitMeasure: "",
                            //     quantity: null
                            //   })
                            // }}
                        />
                    </div>
                </form>
            </Container>
        </div>
    );
};

export default NewRecipe;
import React from 'react';
import { Hidden, Paper } from '@mui/material';
import AppBarComponent from './AppBar';
import CustomDrawerComponent from './Drawer';
import TabBar from '../../routes/TabBar'
import { Outlet } from 'react-router-dom';
import {Box} from '@mui/material';

const Structure = ({ pageTitleLocal }) => {
    const [openLocal, setOpenLocal] = React.useState(false);
    const toggleDrawerLocal = () => {
        setOpenLocal(!openLocal);
    };

    return (
        <div style={{
            height: '100vh',
            overflow: 'auto',
            // width: '100%',
            boxSizing: 'border-box'
        }}>


            <AppBarComponent open={openLocal} toggleDrawer={toggleDrawerLocal} pageTitle={pageTitleLocal} /> {/* Passa le prop open e toggleDrawer al tuo componente AppBarComponent */}


            <div style={{ display: 'flex' }}>
                <CustomDrawerComponent open={openLocal} toggleDrawer={toggleDrawerLocal} />
                <Box
                    sx={{
                        flex: 1,
                        width: '-webkit-fill-available',
                        marginLeft: { md: '6%' },
                    }}>
                    <Outlet />
                </Box>
            </div>

            <Hidden mdUp>
                <Paper component="nav"
                    // elevation={4} // Imposta l'elevazione per farla sovrapporre al menu drawer
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}>
                    <TabBar />
                </Paper>
            </Hidden>

        </div>
    );
};

export default Structure;

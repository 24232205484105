// File: LoadingProvider.jsx
import React, { createContext, useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;      /* Assicurati che copra tutta la larghezza della viewport */
  height: 100vh;     /* Assicurati che copra tutta l'altezza della viewport */
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;     /* Alto z-index per sovrapporsi a tutti gli elementi */
`;


const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 6px solid #ddd;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoading = () => setIsLoading(true);
  const hideLoading = () => setIsLoading(false);

  return (
    <LoadingContext.Provider value={{ showLoading, hideLoading }}>
      {children}
      {isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;

import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import ListSubheader from '@mui/material/ListSubheader';
// import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon  from '@mui/icons-material/Logout';
import HomeIcon  from '@mui/icons-material/Home';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuBookIcon from '@mui/icons-material/MenuBook';
// import PeopleIcon from '@mui/icons-material/People';
// import BarChartIcon from '@mui/icons-material/BarChart';
import AccountCircleIcon  from '@mui/icons-material/AccountCircle';
// import LayersIcon from '@mui/icons-material/Layers';
// import AssignmentIcon from '@mui/icons-material/Assignment';
// import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { clearAuthToken } from 'react-auth-component';


export const MainListItems  = ({closeDrawer} ) => {

  const navigate = useNavigate();

  const onItemClick = (destination) => {
    navigate(destination); // Naviga alla destinazione
    closeDrawer(); // Chiudi il drawer
  };

  return (
    <React.Fragment>
      <ListItemButton onClick={() => onItemClick('./home')}>
        <ListItemIcon>
          <HomeIcon  />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton onClick={() => onItemClick('./cookbook')}>
        <ListItemIcon>
          <MenuBookIcon />
        </ListItemIcon>
        <ListItemText primary="CookBook" />
      </ListItemButton>
      {/*<ListItemButton onClick={() => onItemClick('./dashboard')}>*/}
      {/*  <ListItemIcon>*/}
      {/*    <AccountCircleIcon />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="Profile" />*/}
      {/*</ListItemButton>*/}
      <ListItemButton onClick={() =>{ clearAuthToken(); onItemClick('../')}}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="LogOut" />
      </ListItemButton>
      {/*<ListItemButton>*/}
      {/*  <ListItemIcon>*/}
      {/*    <LayersIcon />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="Integrations" />*/}
      {/*</ListItemButton>*/}
      {/*<Divider sx={{ my: 1 }} />*/}
      {/*<ListSubheader component="div" inset>*/}
      {/*  Saved reports*/}
      {/*</ListSubheader>*/}
      {/*<ListItemButton>*/}
      {/*  <ListItemIcon>*/}
      {/*    <AssignmentIcon />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="Current month" />*/}
      {/*</ListItemButton>*/}
      {/*<ListItemButton>*/}
      {/*  <ListItemIcon>*/}
      {/*    <AssignmentIcon />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="Last quarter" />*/}
      {/*</ListItemButton>*/}
      {/*<ListItemButton>*/}
      {/*  <ListItemIcon>*/}
      {/*    <AssignmentIcon />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="Year-end sale" />*/}
      {/*</ListItemButton>*/}
    </React.Fragment>
  );

}




import React from "react";
import {Link, useNavigate} from "react-router-dom";
import { Tabs, Tab } from "@mui/material";
// import PhoneIcon from "@mui/icons-material/Phone";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import PersonPinIcon from "@mui/icons-material/PersonPin";
import {clearAuthToken} from "react-auth-component";
import AccountCircleIcon  from '@mui/icons-material/AccountCircle';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LogoutIcon  from '@mui/icons-material/Logout';
import HomeIcon  from '@mui/icons-material/Home';

function TabBar() {
  const navigate = useNavigate();
  return (
    <Tabs
      value={false} // Aggiungi lo stato 'value' se vuoi gestire lo stato attivo della tab
      variant="fullWidth" // Imposta il variant su 'fullWidth' per rendere la TabBar a larghezza piena
      selectionFollowsFocus // Abilita la proprietà 'selectionFollowsFocus'
      aria-label="icon tabs example"
    >
      <Tab label="Home" component={Link} to="./home" icon={<HomeIcon />} />
      <Tab label="CookBook" component={Link} to="./cookbook" icon={<MenuBookIcon />} />
      {/*<Tab label="Profile" component={Link} to="./dashboard" icon={<AccountCircleIcon />} />*/}
      {/*<Tab label="LogOut" component={Link} onClick={() =>{ clearAuthToken(); navigate('../')}} icon={<LogoutIcon />} />*/}
    </Tabs>
  );
}

export default TabBar;

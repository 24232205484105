import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Modal, Autocomplete, CircularProgress, Select, MenuItem } from '@mui/material';
import { fetchDataGet } from '../../utils/API/apiService';



const IngredientModal = ({ open,
    handleClose,
    ingredientSelected,
    setIngredientSelected,
    handleAddIngredient }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [prefix, setPrefix] = useState(' ');


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const queryParams = { search: searchTerm };
                const response = await fetchDataGet("recipe/ingredients/ingredient", queryParams);

                setOptions(response);
                setError(null); // Resetta l'errore se la richiesta va a buon fine
            } catch (error) {
                console.error('Errore fetching data:', error);
                setError('Errore durante il recupero degli ingredienti');
            }
            setLoading(false);
        };
        if (searchTerm.length >= 2) {
            fetchData();
            setPrefix(' ')
        }
    }, [searchTerm]);


    const handlePrefixChange = (event) => {
        setIngredientSelected(
            prevIngredient => ({
                ...prevIngredient,
                prefix:event.target.value
            })
        );
        setPrefix(event.target.value);
    };

    const handleSelectIngredient = (event, value) => {

        if (value) {
            setIngredientSelected(
                prevIngredient => ({
                    ...prevIngredient,
                    id: value.id,
                    name: value.name,
                    unitMeasure: value.unitMeasure
                })
            );
        } else {
            setIngredientSelected(prevIngredient => ({
                ...prevIngredient,
                id: null,
                name: null,
                unitMeasure: ' '
            }));
        }
    };



    const canAddIngredient = ingredientSelected.quantity && ingredientSelected.id;
    const prefixes = ["K", "h", "da", " ", "d", "c", "m"];

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Autocomplete
                    options={options}
                    loading={loading}
                    onChange={handleSelectIngredient}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Cerca ingrediente"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <TextField
                        type={"number"}
                        margin="normal"
                        label="Quantità"
                        value={ingredientSelected?.quantity || ''}
                        onChange={(e) =>
                            setIngredientSelected(prevIngredient => ({
                                ...prevIngredient,
                                quantity: e.target.value
                            }))
                        }
                        sx={{ flex: '2',margin: 0, marginRight: 2 }}
                    />

                    {ingredientSelected?.unitMeasure!=='u' &&  (<Select
                        value={prefix}
                        onChange={handlePrefixChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Unit' }}
                        sx={{ flex: 1 }}
                    >
                        {prefixes.map((prefix) => (
                            <MenuItem key={prefix} value={prefix}>
                                {prefix}{ingredientSelected ? ingredientSelected.unitMeasure : ''}
                            </MenuItem>
                        ))}
                    </Select>)}
                </Box>
                <Button onClick={() => { handleAddIngredient(); handleClose(); }} disabled={!canAddIngredient} variant="contained" color="primary" sx={{ mt: 2 }}>
                    Aggiungi
                </Button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </Box>
        </Modal>
    );
};

export default IngredientModal;

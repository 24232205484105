// import * as React from 'react';
import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import FeaturedPost from '../components/Post/FeaturedPost';
import Box from '@mui/material/Box';
import { fetchDataGet } from '../utils/API/apiService';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';

export default function CookBook() {

    const [recipes, setRecipes] = useState([]);
    const [query, setQuery] = useState('');

    useEffect(() => {

        const fetchRecipes = async () => {
            try {
                const queryParams = { page: 1, limit: 10, search: '' };
                const fetchedRecipes = await fetchDataGet("recipe/recipe/recipeByUser", queryParams);
                setRecipes(fetchedRecipes);
                console.log(fetchedRecipes);
            } catch (error) {
                console.error('Errore durante il recupero delle ricette:', error);
            }
        };

        fetchRecipes();
    }, []);

    const handleSearch = async (searchQuery) => {
        // Logica per eseguire la ricerca e aggiornare le ricette visualizzate
    };

    return (
        <Box sx={{ display: 'flex', position: 'relative', width: '100%',boxSizing: 'border-box' }}>

            {/* <Structure  pageTitleLocal="Home"/> */}
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    marginTop: '64px',

                }}
            >
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4, pb: 8 }}>
                    <main>
                        <Grid container spacing={4}>
                            {recipes.map((post) => (
                                <FeaturedPost key={post.id} post={post} />
                            ))}
                        </Grid>
                    </main>

                </Container>

            </Box>
            <Link to="/newrecipe">
                <Fab
                    size="small"
                    color="secondary"
                    aria-label="add"
                    sx={{ position: 'fixed', bottom: '11%', right: '16px', zIndex: 1 }}
                >
                    <AddIcon />
                </Fab>
            </Link>
        </Box>
    );
}
import axios from 'axios';
import { getAuthToken } from 'react-auth-component';
import notify from "../toaster/toaster";
import {cacheImage, getCachedImage} from "../database/IndexedDB";



export const BASE_URL = process.env.REACT_APP_API_URL;

export const fetchDataGet = async (resourceName,queryParams ={}) => {
  try {
    let authToken= await getAuthToken();
    const response = await axios.get(`${BASE_URL}/${resourceName}`,{
      params: queryParams,
      headers: authToken ? { Authorization: `Bearer ${authToken}` } : {}
    });
    console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};



export const fetchImageGet = async (resourceName, queryParams = {}) => {
  try {
    // Costruisci l'URL completo per la richiesta
    const url = `${BASE_URL}/${resourceName}`;

    // Prova a recuperare l'immagine dalla cache
    const cachedImage = await getCachedImage(url);

    if (cachedImage) {
      return cachedImage;
    } else {
      // Se l'immagine non è nella cache, esegui la richiesta di rete
      let authToken = await getAuthToken();
      const response = await axios.get(url, {
        params: queryParams,
        headers: authToken ? { Authorization: `Bearer ${authToken}` } : {},
        responseType: 'blob',
      });

      // Cache l'immagine ricevuta dalla risposta
      const imageBlob = response.data;
      await cacheImage(url, imageBlob);

      return imageBlob;
    }
  } catch (error) {
    throw error;
  }
};

// export const fetchImageGet = async (resourceName,queryParams ={}) => {
//   try {
//     let authToken= await getAuthToken();
//     const response = await axios.get(`${BASE_URL}/${resourceName}`,{
//       params: queryParams,
//       headers: authToken ? { Authorization: `Bearer ${authToken}` } : {},
//       responseType: 'blob',
//     });
//     // console.log(response.data);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

// export const fetchImageGet = async (resourceName,queryParams ={}) => {
//   try {
//     let authToken= await getAuthToken();
//     const response = await axios.get(`${BASE_URL}/${resourceName}`,{
//       params: queryParams,
//       headers: authToken ? { Authorization: `Bearer ${authToken}` } : {},
//       responseType: 'blob',
//     });
//     // console.log(response.data);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };



export const fetchDataPost = async (resourceName, requestData) => {
  try {
    let authToken= await getAuthToken();
    const response = await axios.post(`${BASE_URL}/${resourceName}`, requestData, {
      headers: authToken ? { Authorization: `Bearer ${authToken}` } : {}
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchDataDelete = async (resourceName,queryParams ={}) => {
  try {
    let authToken= await getAuthToken();
    const response = await axios.delete(`${BASE_URL}/${resourceName}`,{
      params: queryParams,
      headers: authToken ? { Authorization: `Bearer ${authToken}` } : {}
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};


// export const uploadImageApi = async (resourceName, imageFile) => {
//   try {
//     let authToken = await getAuthToken(); // Funzione per ottenere il token di autenticazione
//     const formData = new FormData();
//     formData.append('file', imageFile);
//     // const imageFileData = {
//     //   file:imageFile
//     // }
//     const response = await axios.post(`${BASE_URL}/${resourceName}`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//         Authorization: `Bearer ${authToken}`
//       }
//     });
//     // let idPhoto= response.data?.data?.id;
//     // if(idPhoto){
//     //   updateImageCache(`${BASE_URL}/store/file/${idPhoto}`);
//     // }
//     return response.data.data;
//   } catch (error) {
//     if (error?.response?.data) {
//       notify('error', error.response.data);
//     }
//     console.error('Error uploading image', error);
//     throw error;
//   }
// };

export const uploadImageApi = async (resourceName, imageFile) => {
  try {
    let authToken = await getAuthToken(); // Funzione per ottenere il token di autenticazione
    const formData = new FormData();
    formData.append('file', imageFile);

    const response = await axios.post(`${BASE_URL}/${resourceName}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      }
    });

    // Se l'upload è riuscito, puoi invalidare la cache se l'immagine esiste già
    const imageUrl = `${BASE_URL}/store/file/${response.data.data.id}`;
    await cacheImage(imageUrl, imageFile); // Supponiamo che imageFile sia in formato Blob o simile

    return response.data.data;
  } catch (error) {
    if (error?.response?.data) {
      notify('error', error.response.data);
    }
    console.error('Error uploading image', error);
    throw error;
  }
};
